import React, { useEffect, useRef, useState } from "react";
import { ImVolumeMute, ImVolumeLow, ImVolumeMedium, ImVolumeHigh } from "react-icons/im";

interface VolumeSliderProps {
  volume: number;
  onVolumeChange: (volume: number) => void;
}

const VolumeSlider = ({ volume, onVolumeChange }: VolumeSliderProps) => {
  const maxVolume = 0.5;
  const maxWidth = 240; // Equal to w-60

  const backgroundVolumeBar = useRef<HTMLDivElement | null>(null);
  const volumeBar = useRef<HTMLDivElement | null>(null);
  const volumeBall = useRef<HTMLDivElement | null>(null);

  const [isChangingVolume, setIsChangingVolume] = useState(false);

  const handleVolumeChange = (e: React.PointerEvent<HTMLDivElement>) => {
    if (backgroundVolumeBar.current === null) {
      return;
    }

    if (e.type !== "click" && !isChangingVolume) {
      return;
    }

    const targetRect = backgroundVolumeBar.current.getBoundingClientRect();

    const relativeX = (e.pageX - targetRect.left) / targetRect.width;
    let newVolume = relativeX * maxVolume;

    if (newVolume < 0.0) {
      newVolume = 0.0;
    } else if (newVolume > maxVolume) {
      newVolume = maxVolume;
    }

    onVolumeChange(newVolume);
  };
  
  useEffect(() => {
    if (volumeBar.current !== null) {
      volumeBar.current.style.width = `${maxWidth * volume * (1 / maxVolume)}px`;
    }

    if (volumeBall.current !== null) {
      volumeBall.current.style.left = `${maxWidth * volume * (1 / maxVolume) - 16}px`;
    }
  }, [volume])

  let volumeIcon;
  const size = 30
  const color = "fill-slate-500"

  if (volume === 0.0) {
    volumeIcon = <ImVolumeMute size={size} className={color} />
  } else if (volume / maxVolume < 0.33) {
    volumeIcon = <ImVolumeLow size={size} className={color} />
  } else if (volume / maxVolume < 0.66) {
    volumeIcon = <ImVolumeMedium size={size} className={color} />
  } else {
    volumeIcon = <ImVolumeHigh size={size} className={color} />
  }

  return <div className="flex space-x-5 items-center">
    {volumeIcon}
    <div
      ref={backgroundVolumeBar}
      className="relative w-60 h-4 rounded-full bg-slate-50 cursor-pointer touch-none"
      onClick={handleVolumeChange}
      onPointerMove={handleVolumeChange}
      onPointerDown={() => setIsChangingVolume(true)}
      onPointerUp={() => setIsChangingVolume(false)}
      onPointerLeave={() => setIsChangingVolume(false)}>
      <div ref={volumeBar} className="absolute h-4 rounded-full bg-slate-500 -left-1" />
      <div ref={volumeBall} className="absolute w-6 h-6 bg-slate-500 rounded-full -top-1" />
    </div>
  </div>
}

export default VolumeSlider;
