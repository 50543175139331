import NoiseType from "./NoiseType";

interface NoiseSelectionGroupProps {
  selectedType: NoiseType;
  onClickButton: (noiseType: NoiseType) => void;
}

const NoiseSelectionGroup = ({selectedType, onClickButton}: NoiseSelectionGroupProps) => {
  return <div className="flex space-x-20">
    <NoiseSelectionButton onClick={() => onClickButton(NoiseType.White)} isSelected={selectedType === NoiseType.White} noiseType={NoiseType.White}/>
    <NoiseSelectionButton onClick={() => onClickButton(NoiseType.Pink)} isSelected={selectedType === NoiseType.Pink} noiseType={NoiseType.Pink}/>
    <NoiseSelectionButton onClick={() => onClickButton(NoiseType.Brown)} isSelected={selectedType === NoiseType.Brown} noiseType={NoiseType.Brown}/>
  </div>
};

interface NoiseSelectionButtionProps {
  isSelected: boolean
  noiseType: NoiseType
  onClick: () => void
}

const NoiseSelectionButton = ({isSelected, noiseType, onClick}: NoiseSelectionButtionProps) => {
  const borderColor = isSelected ? "border-white" : "border-transparent"
  const circleColor = (() => {
    switch(noiseType) {
      case NoiseType.White:
        return "bg-slate-100";
      case NoiseType.Pink:
        return "bg-pink-300";
      case NoiseType.Brown:
        return "bg-yellow-700";
    }
  })();

  return <div className={`w-16 h-16 rounded-full flex items-center justify-center border-4 ${borderColor}`}>
    <div className={`w-12 h-12 rounded-full ${circleColor} cursor-pointer`} onClick={() => onClick()}>
    </div>
  </div>
}

export default NoiseSelectionGroup;
