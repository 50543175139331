import NoiseType from "./NoiseType";

declare global {
  interface Window {
    AudioContext: typeof AudioContext;
    webkitAudioContext: typeof AudioContext;
  }
}

const createWhiteNoise = (audioContext: AudioContext) => {
  const bufferSize = 2 * audioContext.sampleRate;
  const noiseBuffer = audioContext.createBuffer(1, bufferSize, audioContext.sampleRate);
  const output = noiseBuffer.getChannelData(0);

  for (var i = 0; i < bufferSize; i++) {
    output[i] = Math.random() * 2 - 1;
  }

  const whiteNoise = audioContext.createBufferSource();
  whiteNoise.buffer = noiseBuffer;
  whiteNoise.loop = true;
  whiteNoise.start(0);

  return whiteNoise;
};

const createPinkNoise = (audioContext: AudioContext) => {
  const bufferSize = 4096;

  const pinkNoise = (() => {
    var b0: number = 0.0;
    var b1: number = 0.0;
    var b2: number = 0.0;
    var b3: number = 0.0;
    var b4: number = 0.0;
    var b5: number = 0.0;
    var b6: number = 0.0;

    const node = audioContext.createScriptProcessor(bufferSize, 1, 1);
    node.onaudioprocess = (e) => {
      const output = e.outputBuffer.getChannelData(0);
      for (var i = 0; i < bufferSize; i++) {
        var white: number = Math.random() * 2 - 1;
        b0 = 0.99886 * b0 + white * 0.0555179;
        b1 = 0.99332 * b1 + white * 0.070759;
        b2 = 0.96900 * b2 + white * 0.1538520;
        b3 = 0.86650 * b3 + white * 0.3104856;
        b4 = 0.55000 * b4 + white * 0.5329522;
        b5 = -0.7616 * b5 - white * 0.0168980;
        output[i] = b0 + b1 + b2 + b3 + b4 + b5 + b6 + white * 0.5362;
        output[i] *= 0.11; // (roughly) compensate for gain
        b6 = white * 0.115926;
      }
    }

    return node;
  })();

  return pinkNoise;
};

const createBrownNoise = (audioContext: AudioContext) => {
  const brownNoise = (() => {
    const bufferSize = 4096;
    var lastOut: number = 0.0;
    var node = audioContext.createScriptProcessor(bufferSize, 1, 1);
    node.onaudioprocess = (e) => {
      const output = e.outputBuffer.getChannelData(0);
      for (var i = 0; i < bufferSize; i++) {
        const white = Math.random() * 2 - 1;
        output[i] = (lastOut + (0.02 * white)) / 1.02;
        lastOut = output[i];
        output[i] *= 3.5; // (roughly) compensate for gain
      }
    }

    return node;
  })();

  return brownNoise;
};

class NoiseGenerator {
  audioContext: AudioContext
  gainNode: GainNode
  whiteNoise: AudioBufferSourceNode
  pinkNoise: ScriptProcessorNode
  brownNoise: ScriptProcessorNode
  volume: number
  isPlaying: boolean
  noiseType: NoiseType | null

  constructor(initialVolume: number, initialNoiseType: NoiseType) {
    const AudioContext = window.AudioContext || window.webkitAudioContext
    this.audioContext = new AudioContext();
    this.audioContext.resume();

    this.whiteNoise = createWhiteNoise(this.audioContext);
    this.pinkNoise = createPinkNoise(this.audioContext);
    this.brownNoise = createBrownNoise(this.audioContext);

    this.gainNode = this.audioContext.createGain();
    this.gainNode.connect(this.audioContext.destination);

    this.volume = initialVolume;
    this.isPlaying = false;

    this.noiseType = null;
    this.setNoiseType(initialNoiseType);
  }

  play() {
    this.gainNode.gain.value = this.volume;
    this.isPlaying = true;
  }

  stop() {
    this.gainNode.gain.value = 0.0;
    this.isPlaying = false;
  }

  setVolume(volume: number) {
    this.volume = volume;

    if (this.isPlaying) {
      this.gainNode.gain.value = this.volume;
    }
  }

  setNoiseType(noiseType: NoiseType | null) {
    if (this.noiseType !== null) {
      switch (this.noiseType) {
        case NoiseType.White:
          this.whiteNoise.disconnect(this.gainNode);
          break;
        case NoiseType.Pink:
          this.pinkNoise.disconnect(this.gainNode);
          break;
        case NoiseType.Brown:
          this.brownNoise.disconnect(this.gainNode);
          break;
      }
    }

    this.noiseType = noiseType

    if (this.noiseType === null) {
      return;
    }

    switch (this.noiseType) {
      case NoiseType.White:
        this.whiteNoise.connect(this.gainNode);
        break;
      case NoiseType.Pink:
        this.pinkNoise.connect(this.gainNode);
        break;
      case NoiseType.Brown:
        this.brownNoise.connect(this.gainNode);
        break;
    }
  }
}

export default NoiseGenerator;
