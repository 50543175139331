import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function App() {
  return (
    <div className="fixed h-full w-full bg-gray-700 flex items-center justify-center">
      <Helmet>
        <title>Ponchotang</title>
        <meta name="description" content="Random shit made by Ponchotang" />
      </Helmet>

      <h1 className="font-sans font-bold text-2xl text-white">
        go aw<Link to="/about">a</Link><Link to="/noise">y</Link> :)
      </h1>
    </div>
  )
}
