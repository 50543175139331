import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import App from './App';
import About from './about/About';
import Noise from './noise/Noise';

const rootElement = document.getElementById('root') as HTMLElement;
const app = ReactDOM.createRoot(rootElement);

app.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="about" element={<About />} />
      <Route path="noise" element={<Noise />} />
    </Routes>
  </BrowserRouter>
);
