import { BsPlayFill, BsPauseFill } from 'react-icons/bs';

interface PlayPauseButtonProps {
  onClick: () => void;
  isPlaying: boolean
}

const PlayPauseButton = ({onClick, isPlaying}: PlayPauseButtonProps) => {
  const size = 100;
  const color = "white";
  const onClickHandler = () => onClick();

  let button;

  if (isPlaying) {
    button = <BsPauseFill className="cursor-pointer" size={size} color={color} onClick={onClickHandler} />
  } else {
    button = <BsPlayFill className="cursor-pointer" size={size} color={color} onClick={onClickHandler} />
  }

  return button;
}

export default PlayPauseButton;
