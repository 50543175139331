import { Helmet } from "react-helmet";
import { useEffect, useState, useRef } from "react";
import PlayPauseButton from "./PlayPauseButton";
import NoiseGenerator from "./NoiseGenerator";
import NoiseSelectionGroup from "./NoiseSelectionGroup";
import VolumeSlider from "./VolumeSlider";
import NoiseType from "./NoiseType";

const Noise = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(0.1);
  const [noiseType, setNoiseType] = useState(NoiseType.White);

  const noiseGeneratorRef = useRef<NoiseGenerator | null>(null);

  useEffect(() => {
    if (noiseGeneratorRef.current === null && !isPlaying) {
      console.log("NoiseGenerator is null");
      return;
    }

    if (noiseGeneratorRef.current === null && isPlaying) {
      noiseGeneratorRef.current = new NoiseGenerator(volume, noiseType);
    }
  }, [isPlaying, volume, noiseType]);

  useEffect(() => {
    if (noiseGeneratorRef.current === null && !isPlaying) {
      console.log("NoiseGenerator is null");
      return;
    }

    let noiseGenerator = noiseGeneratorRef.current!;
    
    if (isPlaying) {
      console.log("Playing");
      noiseGenerator.play();
    } else {
      console.log("Stopped");
      noiseGenerator.stop();
    }
  }, [isPlaying]);

  useEffect(() => {
    if (noiseGeneratorRef.current === null) {
      return;
    }

    noiseGeneratorRef.current.setVolume(volume);
  }, [volume])

  useEffect(() => {
    if (noiseGeneratorRef.current === null) {
      return;
    }

    noiseGeneratorRef.current.setNoiseType(noiseType);
  }, [noiseType]);

  return <div className="fixed h-full w-full bg-gray-700 flex flex-col items-center justify-center space-y-20">
    <Helmet>
      <title>Noise Generator</title>
      <meta name="description" content="A simple to use white, pink, and brown noise generator for sleep and focus." />
    </Helmet>

    <h1 className="font-sans font-bold text-6xl text-white text-center">
      Noise Generator
    </h1>
    <PlayPauseButton onClick={() => setIsPlaying(!isPlaying)} isPlaying={isPlaying} />
    <NoiseSelectionGroup selectedType={noiseType} onClickButton={(noiseType) => setNoiseType(noiseType)}/>
    <VolumeSlider volume={volume} onVolumeChange={(newVolume) => setVolume(newVolume)} />
  </div>
}

export default Noise;
